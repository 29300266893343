import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import styled from 'styled-components'
import useInView from 'react-cool-inview'
import { Link as GatsbyLink } from 'gatsby'

import { Typography, useMediaQuery } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowForward'
import { useTheme } from '@material-ui/core/styles'

const ImgContainer = styled.div`
  .gatsby-image-wrapper {
    width: 50%;
  }
  @media (max-width: 1279.95px) and (min-width: 960px) {
    .gatsby-image-wrapper {
      width: 60%;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '6em 2em',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    flexBasis: '80%',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
    },
  },
  textbox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.only('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    marginTop: theme.spacing(4),
    marginBottom: '1rem',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },

  btnTxt: {
    height: '100%',
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),

    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'initial',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: '3em',
    paddingInline: '40px',
    height: '37px',
    alignItems: 'center',
    backgroundColor: theme.palette.text.primary,
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

export const SellSection = () => {
  const classes = useStyles()
  const intl = useIntl()
  const ref: any = useRef()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))

  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      ref.current?.play()
    },
  })
  return (
    <Grid container className={classes.root}>
      <Grid container spacing={3} className={classes.innerDiv}>
        <Grid item lg={6} xs={12}>
          <Image
            src="sellproperty.png"
            className={classes.img}
            alt="Broooklyn bridge"
            layout="CONSTRAINED"
            style={{ height: '100%', width: '100%', zIndex: 0, borderRadius: '8px' }}
          />
        </Grid>
        <Grid item lg={6} md={12} className={classes.textbox}>
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Vendre_3' })}</span>
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomButton
              displayIcon={true}
              icon={() => <ArrowDownwardIcon />}
              href={`/${intl.locale}/agency`}
              width={'100%'}
            >
              <Typography className={classes.btnTxt} style={{ textTransform: 'uppercase' }}>
                {intl.formatMessage({ id: 'new.Vendre_4' })}
              </Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SellSection
