import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { IconButton, Tooltip, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 2em',
    [theme.breakpoints.between('sm', 'sm')]: {
      marginTop: '40px',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      width: '90%',
    },
    [theme.breakpoints.between('xs', 'xs')]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(15),
    },
  },
  rootSell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // padding: '0 2em',
    marginBottom: '21px',
    [theme.breakpoints.between('sm', 'sm')]: {
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.between('md', 'xl')]: {
      // width: '90%',
    },
    [theme.breakpoints.between('xs', 'xs')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      padding: '0em',
    },
  },
  circle: {
    height: '7em',
    width: 'auto',
    position: 'absolute',
    marginTop: '-10em',
    marginLeft: '17em',
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'inherit',
      marginTop: 'inherit',
      marginLeft: 'inherit',
      marginBottom: '2em',
      height: '5em',
    },
    [theme.breakpoints.between('md', 'md')]: {
      height: '6em',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(52),
    lineHeight: '52px',
    fontWeight: 600,
    marginBottom: '21px',

    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
      marginTop: '2em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
      marginTop: '1em',
    },
  },
  details: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(55),
    lineHeight: 1.2,
    marginBottom: '2em',
    padding: '0 100px',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
      padding: '0 20px',
    },
    [theme.breakpoints.between('lg', 'lg')]: {
      fontSize: theme.typography.pxToRem(40),
      padding: '0 20px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(30),
      padding: '0 20px',
    },
  },
  detailsBuy: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(55),
    lineHeight: 1.2,
    marginBottom: '1em',
    padding: '0 100px',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
      padding: '0 20px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
      padding: '0 20px',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: theme.typography.pxToRem(45),
    },
  },
  link: {
    fontSize: theme.typography.pxToRem(13.8),
    marginBottom: 'em',
    display: 'flex',
    paddingBottom: '0.5em',
    justifyContent: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(8),
    },
  },
  btn: {
    color: 'black',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    padding: '5px 0',
    textTransform: 'uppercase',
    fontFamily: `'Gotham book', serif`,
    fontSize: '12px',
    fontWeight: 700,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
}))

interface HighLightsProps {
  title: string
  detail: string
  linkTxt: string
  href?: string
  buy?: boolean
  sell?: boolean
}

export const HighLights: React.FC<HighLightsProps> = (props) => {
  const { title, detail, linkTxt, href, buy, sell } = props
  const classes = useStyles()

  return (
    <Grid justifyContent="center" className={sell ? classes.rootSell : classes.root}>
      <Box className={classes.title}>
        <Box>{title}</Box>
      </Box>
      {/* <Box className={buy ? classes.detailsBuy : classes.details}>{detail}</Box> */}
      {!buy && (
        <Box className={classes.link}>
          <AnchorLink to={href} className={classes.btn}>
            {linkTxt} <ArrowDownwardIcon style={{ fontSize: 'medium', fontWeight: 'bold' }} />
          </AnchorLink>
        </Box>
      )}
    </Grid>
  )
}

export default HighLights
