import React from 'react'

import Grid from '@material-ui/core/Grid'
import { Banner } from '../component/Banner'
import { Display } from '../component/page_component/Display'
import { Estimate } from '../component/page_component/Estimate'
import { HighLights } from '../component/page_component/HighLights'
import { Layout } from '../component/Layout'
import { PageProps } from 'gatsby'
import { Technology } from '../component/page_component/Technology'
import { useIntl } from 'gatsby-plugin-intl'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import hreflangConfig from '../utils/hreflangConfig'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from '../component/Images'
import SellSection from '../component/page_component/Sell'
import SeoContent from '../component/page_component/SeoContent'
import { useMediaQuery } from '@material-ui/core'
import theme from '../Theme/theme'
import { useTheme } from '@material-ui/core/styles'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allProperty (
    filter: {
      isOffmarket: { eq: false }
      statutVente: { eq: "Vendu" }
      dateDebutMendat:{ne:null}
   

    
    }
    limit: 10
    sort: { fields: dateDebutMendat, order: DESC }
  ) {
        nodes  {
          id
          ascenseur
          alarme
          airConditionne
          piscine
          bienEnCopro
          charges

        honorairesVente

        isOffmarket

        lifeStyle
        surfaceJardin
        surfaceTerrasse

        suiviPar {
          photo {
            portraitSquare
          }
          phone
          name
          email
        }
        photos {
          large
        }

        codePostal
        createdAt
        departement
        region
        descriptionAr
        descriptionEn
        descriptionEs
        descriptionFr
        descriptionPt
        descriptionRu
        descriptionZh
        nombreChambres
        nombrePieces
        nombreSallesDeBain
        pays
dateDebutMendat
        prixAffichageFai
        prixConfidentiel
        prixLoyer

        propertyType
        propertyTypeDisplay
        propertyTypeSlug
        reference

        surfaceHabitable
        titreAr
        titreEn
        titreEs
        titreFr
        titrePt
        titreRu
        titreZh
        unitArea
        unitPrice

        ville
        villeFr
        villeEn
        villePt
        villeEs

        statutVente
        lat
        lng
        propertyTypeSlug
      }
    }

    allPropertyDistinct: allProperty {
      distinct(field: propertyType)
    }
  }
`


const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40%',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    paddingInline: theme.spacing(7),
    fontSize: '52px',
    [theme.breakpoints.only('lg')]: {
      paddingInline: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      paddingInline: '24px',
      // height: '60vh',
      alignItems: 'center',
      // paddingTop: '70px',
      paddingBlock: '150px 80px',
    },
  },
  img: {},
  sellproperty: {},
}))

const Sell: React.FC<PageProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    allProperty: { nodes },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY)
  const { location } = props

  const isSMDown = useMediaQuery(theme.breakpoints.down('md'))



  const intl = useIntl()
  const canonicalUrl = `${siteUrl}${location.pathname}`

  const hreflangLinks = hreflangConfig['/fr/sell/']

  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: 'PageTitleSell' })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Vendre avec nous - Kretz" />
      </Helmet>

      <Grid container justifyContent="center" id="sell-with-kretz" className={classes.main}>
        <Grid item lg={6} md={12} className={classes.title}>
          <HighLights
            title={intl.formatMessage({ id: 'sell.title' })}
            detail=""
            linkTxt={intl.formatMessage({ id: 'sell.link' })}
            href={`/${intl.locale}/sell#estimate-my-property`}
            sell={true}
            buy={isSMDown ? true : false}
          />
        </Grid>
        <Grid item md={6} style={{ display: isSMDown ? 'none' : 'block' }}>
          <Image
            src="sellpropertybanner.png"
            alt="Sell Property Banner"
            className={classes.img}
            layout="CONSTRAINED"
            style={{ height: '50%', width: '100%', zIndex: 0 }}
          />
        </Grid>
      </Grid>
      <Grid container id="estimate-my-property">
        <Estimate defaultOpen />
      </Grid>
      <Grid container id="estimate-my-property" className={classes.sellproperty}>
        <SellSection />
      </Grid>

      <Grid container id="sold-goods">
        {/* <Display
          bottomGrey
          displayCTA={false}
          title={intl.formatMessage({ id: 'goodsSold' })}
          edges={nodes}
        /> */}
        <Display
          bottomGrey
          displayCTA={false}
          title={intl.formatMessage({ id: 'new.Vendre_5' })}
          edges={nodes}
        />
      </Grid>



      {/* <SeoContent /> */}
    </Layout>
  )
}

export default Sell
